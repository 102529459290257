//toggleLogEventsToConsole
import { createSlice } from '@reduxjs/toolkit';

interface IDevState {
    showDevPanel: boolean;
    logEventsToConsole: boolean;
    devMode: boolean;
}

export const initalDevState: IDevState = {
    showDevPanel: false,
    //logEventsToConsole: Boolean(localStorage.getItem('DEV_logEventsToConsole') ?? false),
    logEventsToConsole: Boolean(false),
    devMode: false,
};

export const devSlice = createSlice({
    name: 'devState',
    initialState: initalDevState,
    reducers: {
        toggleShowDevPanel: (state) => {
            state.showDevPanel = !state.showDevPanel;
        },
        toggleLogEventsToConsole: (state) => {
            state.logEventsToConsole = !state.logEventsToConsole;
        },
        toggleDevMode: (state) => {
            state.devMode = !state.devMode;
        },
        // actionWithPayload: (state, action) => {},
    },
});

// Action creators are generated for each case reducer function
export const { toggleLogEventsToConsole, toggleShowDevPanel, toggleDevMode } = devSlice.actions;

export default devSlice.reducer;
