import React, { useEffect, useState } from 'react';
import { Button, Link } from '@amzn/awsui-components-react';
import RawHTMLRenderer from 'src/utils/rawRenderer';
import { Flashbar } from '@amzn/awsui-components-react/polaris';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';

interface ILevels {
    [key: number]: { status: string; type: 'error' | 'success' | 'warning' | 'info' | undefined };
}

const LEVEL_TYPES: ILevels = {
    0: {
        status: 'OK',
        type: 'success',
    },
    1: {
        status: 'Info',
        type: 'info',
    },
    2: {
        status: 'Warn',
        type: 'warning',
    },
    3: {
        status: 'Down',
        type: 'error',
    },
};

interface IsItDownState {
    name: string;
    message: string;
    level: number;
    tickets: string;
}

export default function IsItDown(props: { componentId?: number }) {
    const [flashbarItems, setFlashbarItems] = useState([] as FlashbarProps.MessageDefinition[]);
    // login component
    // Overall
    const IS_IT_DOWN_URL = `https://is-it-down.amazon.com/sidefar/components/${props.componentId ?? 19636}`;
    const IS_IT_DOWN_API = `${IS_IT_DOWN_URL}.js`;

    interface IsItDownState {
        name: '';
        message: '';
        level: 0;
        tickets: '';
    }

    let notification: FlashbarProps.MessageDefinition;

    useEffect(() => {
        fetch(IS_IT_DOWN_API)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (!data || data.level == 0) return;
                let notificationMessage = (
                    <>
                        <RawHTMLRenderer html={data.message} />
                        {data.tickets ? ' - ' : ''}
                        {data.tickets
                            ? data.tickets.split(',').map((ticket: string) => (
                                  <Link
                                      href={`http://tt.amazon.com/${ticket.trim()}`}
                                      key={ticket}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                  >
                                      {`${ticket}, `}
                                  </Link>
                              ))
                            : ''}
                    </>
                );

                notification = {
                    type: LEVEL_TYPES[data.level].type ?? 'info',
                    content: notificationMessage,
                    action: (
                        <Button iconName={'external'} href='https://is-it-down.amazon.com/sidefar/'>
                            Is FAR down?
                        </Button>
                    ),
                    //dismissible: true,
                    header: <>Broadcast Message</>,
                    //onDismiss: () => dispatch(setFlashbarItems([])),
                };
                setFlashbarItems([notification]);
            });
        return () => {};
    }, []);

    return (
        <>
            <Flashbar items={flashbarItems} />
        </>
    );
}
