import React, { useEffect, useState } from 'react';
import { Flashbar } from '@amzn/awsui-components-react/polaris';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { useAppDispatch, useAppSelector } from '../stores/slices/hooks';
import { removeUserNotification, userNotificationCountdown } from '../stores/slices/userSlice';
import IsItDown from 'src/components/IsItDown';

export function TopNotification() {
    const dispatch = useAppDispatch();
    const [timerRunning, setTimerRunning] = useState(false);
    const userNotifications = useAppSelector((state) => state.userState.userNotifications);
    const showSelectSite = useAppSelector((state) => state.userState.showSelectSite);
    const showUserPreferences = useAppSelector((state) => state.userState.showUserPreferences);

    function flashbarCountdown() {
        dispatch(userNotificationCountdown());
    }

    useEffect(() => {
        //log(`userNotifications changed ${userNotifications.length} ${timerRunning}`);
        if (userNotifications.length) {
            setTimerRunning(true);
            setTimeout(flashbarCountdown, 1000);
        }
        if (userNotifications.length <= 0) {
            setTimerRunning(false);
        }
        return () => {
            userNotifications;
        };
    }, [userNotifications, showSelectSite, showUserPreferences]);

    return (
        <>
            <IsItDown />
            <Flashbar
                items={userNotifications.map((notification, index) => {
                    return {
                        type: notification.type,
                        content: `${notification.content} ...(${notification.displayTimeout}s)`,
                        dismissible: true,
                        header: 'GSO Broadcast Message',
                        dismissLabel: 'Dismiss',
                        onDismiss: () => dispatch(removeUserNotification(index)),
                    } as FlashbarProps.MessageDefinition;
                })}
            />
        </>
    );
}

export default TopNotification;
