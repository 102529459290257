import { AnyAction, combineReducers, configureStore, Store, ThunkDispatch } from '@reduxjs/toolkit';
import userStateReducer, { initalUserState } from './userSlice';
import userPrefsStateReducer, { IUserPreferences, userPrefsState } from './userPrefsSlice';
import rootSaga from '../../components/sagas';
import createSagaMiddleware from 'redux-saga';
import devStateReducer, { initalDevState } from './devSlice';
import devicesStateReducer, { initialDevicesState } from './devicesSlice';
import { Reducer } from 'react';
import { log } from 'src/utils/helpers';

export type TAppState = ReturnType<typeof rootReducer>;
export type TDispatch = ThunkDispatch<TAppState, void, AnyAction>;
export type TStore = Store<TAppState, AnyAction> & { dispatch: TDispatch };
export type TGetState = () => TAppState;

type TAction = AnyAction;

export type TRootReducer = Reducer<
    {
        devState: typeof devStateReducer;
        deviceState: typeof devicesStateReducer;
        userState: typeof userStateReducer;
        userPrefsState: typeof userPrefsStateReducer;
    },
    TAction
>;

export const loadUserPrefsState = () => {
    try {
        const serializedState = localStorage.getItem('userPrefsState');
        if (!serializedState) return undefined;
        else {
            const prefs: IUserPreferences = JSON.parse(serializedState);
            return prefs;
        }
    } catch (err) {
        return undefined;
    }
};

export const saveUserPrefsState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state.userPrefsState);
        localStorage.setItem('userPrefsState', serializedState);
    } catch (err) {
        log(err, true);
    }
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    devState: devStateReducer,
    deviceState: devicesStateReducer,
    userState: userStateReducer,
    userPrefsState: userPrefsStateReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
    preloadedState: {
        userPrefsState: loadUserPrefsState(),
        userState: initalUserState,
        deviceState: initialDevicesState,
        devState: initalDevState,
    },
    devTools: true,
    // TODO: Turn this off in production eventually
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
    saveUserPrefsState(store.getState());
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
