export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
export const GET_USER_SITES = 'GET_USER_SITES';
export const GET_DEVICES_FOR_SITES = 'GET_DEVICES_FOR_SITES';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';

export const getUserSitesAction = () => ({
    type: GET_USER_SITES,
});
export const getUserPreferencesAction = () => ({
    type: GET_USER_PREFERENCES,
});
export const getUserNotificationsAction = () => ({
    type: GET_USER_NOTIFICATIONS,
});
export const getDevicesForSites = (sites: string[]) => ({
    type: GET_DEVICES_FOR_SITES,
});
// https://codesandbox.io/s/6mgb2?file=/src/redux/posts/saga.js
