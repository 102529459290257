import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectProps } from '@amzn/awsui-components-react';

export interface IUserPreferences {
    darkMode: boolean;
    uiDensity: boolean;
    visualRefresh: boolean;

    tutorialCompleted: boolean;

    selectedSites: string[];
    selectedSiteOptions: any;
}

const initialState: IUserPreferences = {
    darkMode: false,
    uiDensity: true,
    visualRefresh: false,

    tutorialCompleted: false,

    selectedSites: [],
    selectedSiteOptions: [],
};

export const userPrefsState = createSlice({
    name: 'userPrefsState',
    initialState: initialState,
    reducers: {
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
        },
        toggleVisualRefresh: (state) => {
            state.visualRefresh = !state.visualRefresh;
        },

        toggleUiDensity: (state) => {
            state.uiDensity = !state.uiDensity;
        },
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        setTutorialCompleted: (state, action: PayloadAction<boolean>) => {
            state.tutorialCompleted = action.payload;
        },
        setSelectedSites: (state, action: PayloadAction<string[]>) => {
            state.selectedSites = action.payload;
            state.selectedSiteOptions = state.selectedSites.map((s) => {
                return {
                    value: s,
                    label: s,
                } as SelectProps.Option;
            });
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    toggleDarkMode,
    toggleUiDensity,
    toggleVisualRefresh,
    setDarkMode,
    setTutorialCompleted,
    setSelectedSites,
} = userPrefsState.actions;

export const selectTutorialCompleted = (state: any) => state.userPrefsState?.tutorialCompleted;

export default userPrefsState.reducer;
