import React from 'react';
import { useAppDispatch, useAppSelector } from '../stores/slices/hooks';
import Splash from './Splash';
import MainContent from './MainContent';
import { AppLayout } from '@amzn/awsui-components-react';
import { TopNav } from 'src/components/TopNav';
import FileUpload from 'src/components/FileUpload';

function SIDEFARApp() {
    const dispatch = useAppDispatch();
    const allowedSites = useAppSelector((state) => state.deviceState.allowedSites);

    if (!allowedSites.length) {
        return <Splash />;
    } else {
        return (
            <>
                <TopNav />
                <AppLayout
                    headerSelector={'#header'}
                    navigationHide
                    content={<MainContent />} // Main content on the page, defined below
                    toolsHide
                />
                <FileUpload />
            </>
        );
    }
}

export default SIDEFARApp;
