import { API, graphqlOperation } from '@aws-amplify/api';
import { putMetricFromClient } from 'src/graphql/mutations';
import { DeviceFromDDV, TreeDevice, prefixes } from 'src/types';

const parentDeviceRegx = /^(uc_|dcmsn_|keep_|)\((FC|Portable|NT4S|Dev|Test)\)\s([A-Za-z0-9]{4,6})\-(A([0-9]{1,4})|I(\d))/;

export function log(object: any, error: boolean = false, object2: any = null) {
    if (error) {
        if (object2 != null) {
            console.error(object, object2);
        } else {
            console.error(object);
        }
    } else {
        if (object2 != null) {
            console.log(object, object2);
        } else {
            console.log(object);
        }
    }
}

export function getTimeString(timestamp: number) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export function replaceAt(array: any, index: number, value: any) {
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
}

export function isDeviceNameValid(device: DeviceFromDDV | TreeDevice, parentDeviceName?: string): boolean {
    let regx = /NOMATCH/g;
    const alarmPanelRegx = /^(uc_|dcmsn_|keep_|)([A-Za-z0-9]{4,6})\-(\d{1,2})\.([0-3])\.([0-2][0-9]|3[0-1])\.(\d{2})\-(IN|OUT)/;
    switch (device.device_type_name_special) {
        case 'parent_device':
            // accomodating both ISC and bosch panel naming conventions
            // A and 2-4 digit after - for ISC, I and 1 digit after - for bosch.
            regx = parentDeviceRegx;
            break;
        case 'alarm_panel_input':
        case 'alarm_panel_output':
            regx = /^(uc_|dcmsn_|keep_|)([A-Za-z0-9]{4,6})\-(\d{1,2})\.(0[0-9]|1[0-6])\-.*/;
            break;
        case 'intrusion_panel_input':
        case 'account_input':
        case 'bosch_point':
            regx = /^(uc_|dcmsn_|keep_|)([A-Za-z0-9]{4,6})\-I([0-9])\.[0-9]{3}\-.*/;
            break;
        case 'alarm_panel':
            regx = alarmPanelRegx;
            break;
        case 'card_reader_aux_input_1':
        case 'card_reader_aux_input_2':
        case 'card_reader_aux_output_1':
        case 'card_reader_aux_output_2':
        case 'intrusion_panel_output_onboard':
        case 'camera':
            regx = /^(uc_|dcmsn_|keep_|)([A-Za-z0-9]{4,6})\-.*/;
            break;
        case 'card_reader':
            regx = /^(uc_|dcmsn_|keep_|)([A-Za-z0-9]{4,6})\-(\d{1,2})\.([0-3])\.([0-2][0-9]|3[0-1])\-.*/;
            break;
        case 'lidar': // we do not have convention for this and don't allow changing the name either.
            return true;
        default:
            log(`Unhandled device type at checkDeviceNameInvalid: ${device.device_type_name_special}`, true);
            break;
    }
    const matches = device.device_name.match(regx);
    if (matches && ['alarm_panel', 'card_reader'].includes(device.device_type_name_special)) {
        try {
            const ISCNumberFromName = Number.parseInt(matches[3]);
            const communicationPortFromName = Number.parseInt(matches[4]);
            // Numbering for alarm_panel addresses numbering is off by 1 between
            // DB and Lenel UI. Compensating here to correctly match the addresses.
            const communicationAddressFromName =
                matches[5] ?
                    (
                        device.device_type_name_special === 'alarm_panel' &&
                            device.DeviceSource === 'onguard' ?
                            Number.parseInt(matches[5]) + 1 : Number.parseInt(matches[5])
                    ) : NaN;

            const parentRegexMatch = parentDeviceName ?
                parentDeviceName.match(parentDeviceRegx)! :
                device.parent_device_name.match(parentDeviceRegx)!;
            const ISCNumberFromParentName = Number.parseInt(parentRegexMatch[5]);

            const result = ISCNumberFromName === ISCNumberFromParentName &&
                communicationPortFromName === device.communication_port &&
                communicationAddressFromName === device.communication_address;
            if (!result) {
                log(
                    `ISCNumber/Port/address mismatch: ${device.device_name}. 
                    ${JSON.stringify({ISCNumberFromName,communicationPortFromName, communicationAddressFromName})},
                    ${JSON.stringify({ISCNumberFromParentName, communicationPort: device.communication_port, communicationAddress: device.communication_address})}`
                );
            }
            return result;
        } catch (err) {
            return false;
        }
    }

    if (
        matches &&
        ['alarm_panel_input', 'alarm_panel_output'].includes(device.device_type_name_special) &&
        isDeviceSubChild(device)
    ) {
        try {
            // There are cases on Feenics devices when parent of alarm_panel_input/output 
            // is not alarm_panel, but card_reader instead. If the parent device cannot be
            // matched by the alarm_panel regex, it's probably not an alarm_panel and the
            // will be skipped.
            const alarmPanelMatch = device.child_device_name.match(alarmPanelRegx);
            if (alarmPanelMatch) {
                const alarmPanelNum = Number.parseInt(alarmPanelMatch[6]);
                const alarmIOPanelNum = Number.parseInt(device.device_name.match(regx)![3]);
                const panelNumbersMatch = alarmPanelNum === alarmIOPanelNum;
                if (!panelNumbersMatch) {
                    log(
                        `alarm_panel(${device.child_device_name}) panel number(${alarmPanelNum}) does not` +  
                        `match alarm_panel_input/alarm_panel_output(${device.device_name}) panel number(${alarmIOPanelNum}).`
                    );
                }
                return panelNumbersMatch;
            }
        } catch (err) {
            log(
                `Error ocurred when matching alarm_panel(${device.child_device_name})` +  
                `and alarm_panel_input/alarm_panel_output(${device.device_name}) panel numbers.`
            );
            return false;
        }
    }
    return Boolean(matches);
}

export function checkDeviceSiteCodeMatchesParent(childName: string, parentName: string): boolean {
    const parentSiteCode = getParentSiteCode(parentName);
    const childSiteCode = getGeneralDeviceSiteCode(childName);
    if (!parentSiteCode || !childSiteCode) {
        return false;
    }
    return parentSiteCode === childSiteCode;
}

export function checkParentSiteCodeExists(device: DeviceFromDDV | TreeDevice, sites: string[]): boolean {
    const parentSiteCode = getParentSiteCode(device.parent_device_name);
    if (!parentSiteCode) {
        return false;
    }
    return sites.includes(parentSiteCode);
}

export function getParentSiteCode(deviceName: string) {
    const parentMatch = deviceName.match(parentDeviceRegx);
    if (!parentMatch) {
        console.error(`Could not match ${deviceName}.`)
        return undefined;
    }
    return parentMatch[3];
}

export function getGeneralDeviceSiteCode(deviceName: string) {
    const generalDeviceRegx = /^(uc_|dcmsn_|keep_|)([A-Za-z0-9]{4,6})\-.*/;
    const match = deviceName.match(generalDeviceRegx);
    if (!match) {
        console.error(`Could not match ${deviceName}.`)
        return undefined;
    }
    return match[2];
}

export function isDeviceTypeValid(device: DeviceFromDDV | TreeDevice): boolean {
    if (!['card_reader', 'alarm_panel_input', 'alarm_panel_output'].includes(device.device_type_name_special)) {
        return true;
    }
    return allowedDeviceTypes.find(type => device.device_name.includes(type)) !== undefined
}

export function getDeviceById(
    deviceList: TreeDevice[],
    parentId: number,
    childId: number,
    subChildId: number
): TreeDevice | undefined {
    const deviceToReturn = deviceList.filter((device) => {
        return (
            device.parent_device_id == parentId &&
            device.child_device_id == childId &&
            device.subchild_device_id == subChildId
        );
    })[0];
    return deviceToReturn;
}

export function isDeviceParent(device: DeviceFromDDV) {
    return device.child_device_id === 0 && device.subchild_device_id === 0;
}

export function isDeviceChild(device: DeviceFromDDV) {
    return device.child_device_id !== 0 && device.subchild_device_id === 0;
}

export function isDeviceSubChild(device: DeviceFromDDV) {
    return device.subchild_device_id !== 0;
}

export function getNameWithoutPrefix(deviceName: string): string {
    return deviceName.split(new RegExp('^' + prefixes.join('|')))[1] || deviceName;
}

export function getPrefixFromName(deviceName: string): string | null {
    const match = deviceName.match(new RegExp('^' + prefixes.join('|')));
    return match ? match[0] : null;
}

function hasPrefix(deviceName: string): boolean {
    return deviceName.match(new RegExp('^' + prefixes.join('|'))) !== null;
}

export function prefixWasRemoved(oldName: string, newName: string): boolean {
    return hasPrefix(oldName) && !hasPrefix(newName);
}

export function prefixWasAdded (oldName: string, newName: string): boolean {
    return !hasPrefix(oldName) && hasPrefix(newName);
}

export const INVALID_NAME_SUBMITTED_METRIC_NAME = 'invalid_name_submitted';

export async function putMetricSilent(metricName: string, metricValue: number) {
    try {
        console.log(`Logging ${metricName} metric.`);
        await API.graphql(graphqlOperation(putMetricFromClient, { metricName, metricValue }));
    } catch(err) {
        console.error('Failed to post metric.', err);
    }
}

const allowedDeviceTypes = [
    "12V",
    "220V",
    "24V",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "ACD",
    "ACP",
    "ACS",
    "ALARM",
    "ANTI PASSBACK",
    "AO",
    "AUTOLTR",
    "AUTORTR",
    "BADGE",
    "BATTERY FAIL",
    "BATTERY",
    "BGA",
    "BOILER",
    "CAB",
    "CABINET",
    "CAGE",
    "CLIMB OVER",
    "CLIMB",
    "COM KEYPAD",
    "DC",
    "DR",
    "DSP",
    "DURESS",
    "EDR",
    "ELEC NOTIF SYS",
    "EXD",
    "FAIL",
    "FAULT",
    "FORCED",
    "GATE",
    "GLS BRK",
    "HATCH",
    "HELD",
    "I1",
    "I2",
    "I3",
    "I4",
    "I5",
    "I6",
    "I7",
    "I8",
    "I9",
    "IDF",
    "INPUT",
    "INTRUSION",
    "INVALID",
    "IR",
    "ISC",
    "KEY OVERRIDE SWITCH",
    "KEY OVERRIDE",
    "KEY SWITCH",
    "KEY",
    "LANE",
    "LASER",
    "LATCH",
    "LED",
    "LOITER",
    "LRMD",
    "LW",
    "MD",
    "MK",
    "NO CR",
    "OHD",
    "OVER",
    "OVERRIDE",
    "PIDS",
    "POWER FAIL",
    "PUNCH",
    "RDR",
    "RDR",
    "RED",
    "REN",
    "RM",
    "S/L",
    "SD",
    "SENSOR",
    "SHUNT",
    "SNDR",
    "SPD GATE",
    "STROBE",
    "SWITCH",
    "T/S",
    "TAILGATE",
    "TAMPER",
    "TRNSFRM",
    "WC",
    "WNDW",
    "XD",
]