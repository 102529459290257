import React from 'react';
import App from './components/App';
import store from './stores/slices/store';
import { Provider } from 'react-redux';
import { envVariables } from 'src/resources/envVariables';
import './index.scss';
import './i18n';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Spinner } from '@amzn/awsui-components-react';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import ReactDOM from 'react-dom';

const callbackUrl = window.location.origin;
localStorage.testcallbackURL = callbackUrl;
const config = {
    oauth: {
        domain: envVariables.identifyPoolDomain,
        scope: ['openid'],
        redirectSignIn: callbackUrl,
        redirectSignOut: callbackUrl,
        responseType: 'code',
    },
    // Cognito
    aws_cognito_identity_pool_id: envVariables.identityPoolId,
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: envVariables.userPoolId,
    aws_user_pools_web_client_id: envVariables.userPoolClientId,
    // AppSync
    aws_appsync_graphqlEndpoint: `https://${envVariables.appsyncEndpoint}/graphql`,
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    API: {
        graphql_endpoint: `https://${envVariables.appsyncEndpoint}/graphql`,
        graphql_headers: async () => ({
            Authorization: (await Auth.currentAuthenticatedUser())
                .getSignInUserSession()
                .getIdToken()
                .getJwtToken(),
        }),
    },
};

Amplify.configure(config);

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <React.Suspense fallback={<Spinner size='large' />}>
            <Provider store={store}>
                <App />
            </Provider>
        </React.Suspense>
    </I18nextProvider>,
    document.getElementById('root'),
);
