import React from 'react';
import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { addTextToSelected, removeTextFromSelected } from 'src/components/actions/thunks';
import { toggleShowFileUpload } from 'src/stores/slices/userSlice';
import { TreeDevice } from 'src/types';

const ucRegx = /uc_.*/i;
const dcmsnRegx = /dcmsn_.*/i;
const keepRegx = /keep_/i;

function getSubChildren(devices: TreeDevice[], parentId: number, childId: number) {
    return devices.filter((device) => {
        return device.parent_device_id == parentId && device.child_device_id == childId;
    });
}

function getChildren(devices: TreeDevice[], parentId: number) {
    return devices.filter((device) => {
        return device.parent_device_id == parentId && device.subchild_device_id == 0;
    });
}

export function DeviceMenu() {
    const dispatch = useAppDispatch();
    const parentDevices = useAppSelector((state) => state.deviceState.parentDevices);
    const childDevices = useAppSelector((state) => state.deviceState.childDevices);
    const subchildDevices = useAppSelector((state) => state.deviceState.subchildDevices);

    function addUcDcmsnDisabled() {
        let disabled = true;
        // If a child is selected, its subchildren must be selected,
        for (const childDevice of childDevices) {
            for (const subchild of getSubChildren(
                subchildDevices,
                childDevice.parent_device_id,
                childDevice.child_device_id
            )) {
                if (childDevice.checked && !subchild.checked) return true;
            }
            if (childDevice.checked) disabled = false;
        }

        // If parent is selected, all children must be selected, and all their subchildren must be selected,
        // which we already checked
        for (const parentDevice of parentDevices) {
            for (const child of getChildren(childDevices, parentDevice.parent_device_id)) {
                if (parentDevice.checked && !child.checked) return true;
            }
            if (parentDevice.checked) disabled = false;
        }

        // If we get here, the and there is a subchild checked, enable the button
        for (const subchild of subchildDevices) {
            if (subchild.checked) disabled = false;
        }

        return disabled;
    }

    function removeUcDcmsnDisabled(regxMatch: RegExp) {
        let disabled = true;
        // If the parent is checked, and the child is UN checked, don't allow removal
        for (const parentDevice of parentDevices) {
            for (const childDevice of getChildren(childDevices, parentDevice.parent_device_id)) {
                if (parentDevice.checked && !childDevice.checked) return true;
                for (const subchild of getSubChildren(
                    subchildDevices,
                    childDevice.parent_device_id,
                    childDevice.child_device_id
                )) {
                    if (childDevice.checked && !subchild.checked) return true;
                }
                if (childDevice.checked && childDevice.device_name.match(regxMatch)) disabled = false;
            }
            if (parentDevice.checked && parentDevice.device_name.match(regxMatch)) disabled = false;
        }

        // If we get here, the and there is a subchild checked, enable the button
        for (const subchild of subchildDevices) {
            if (subchild.checked && subchild.device_name.match(regxMatch)) disabled = false;
        }

        return disabled;
    }

    function keepAddButtonDisabled() {
        let disabled = true;
        [subchildDevices, childDevices, parentDevices].forEach(devices =>
            devices.forEach(device => {
                if (device.checked && device.DeviceSource !== 'keep') disabled = false;
            }));
        return disabled;
    }

    function keepRemoveButtonDisabled() {
        let disabled = true;
        for (const subchild of subchildDevices) {
            if (subchild.checked && subchild.device_name.match(keepRegx)) disabled = false;
        }
        for (const child of childDevices) {
            if (child.checked && child.device_name.match(keepRegx)) disabled = false;
        }
        for (const parent of parentDevices) {
            if (parent.checked && parent.device_name.match(keepRegx)) disabled = false;
        }
        return disabled;
    }

    return (
        <Box textAlign={'center'}>
            <SpaceBetween size={'s'} direction={'vertical'}>
                <Button
                    data-testid="addUC-button"
                    className={'ninetyWidth'}
                    disabled={addUcDcmsnDisabled()}
                    onClick={() => {
                        dispatch(addTextToSelected({ toAdd: 'uc_' }));
                    }}
                    iconName={'add-plus'}
                >
                    Add uc_
                </Button>
                <Button
                    data-testid="removeUC-button"
                    className={'ninetyWidth'}
                    disabled={removeUcDcmsnDisabled(ucRegx)}
                    onClick={() => {
                        dispatch(removeTextFromSelected({ toRemove: 'uc_' }));
                    }}
                    iconName={'close'}
                >
                    Remove uc_
                </Button>
                <Button
                    data-testid="addDCMSN-button"
                    className={'ninetyWidth'}
                    disabled={addUcDcmsnDisabled()}
                    onClick={() => {
                        dispatch(addTextToSelected({ toAdd: 'dcmsn_' }));
                    }}
                    iconName={'add-plus'}
                >
                    Add dcmsn_
                </Button>
                <Button
                    data-testid="removeDCMSN-button"
                    className={'ninetyWidth'}
                    disabled={removeUcDcmsnDisabled(dcmsnRegx)}
                    onClick={() => {
                        dispatch(removeTextFromSelected({ toRemove: 'dcmsn_' }));
                    }}
                    iconName={'close'}
                >
                    Remove dcmsn_
                </Button>

                <Button
                    data-testid="addKeep-button"
                    className={'ninetyWidth'}
                    disabled={keepAddButtonDisabled()}
                    onClick={() => {
                        dispatch(addTextToSelected({ toAdd: 'keep_' }));
                    }}
                    iconName={'add-plus'}
                >
                    Add keep_
                </Button>
                <Button
                    data-testid="removeKeep-button"
                    className={'ninetyWidth'}
                    disabled={keepRemoveButtonDisabled()}
                    onClick={() => {
                        dispatch(removeTextFromSelected({ toRemove: 'keep_' }));
                    }}
                    iconName={'close'}
                >
                    Remove keep_
                </Button>

                <Button
                    data-testid="fileUpload-button"
                    className={'ninetyWidth'}
                    onClick={() => {
                        dispatch(toggleShowFileUpload());
                    }}
                    iconName={'upload'}
                >
                    Upload CSV
                </Button>
            </SpaceBetween>
        </Box>
    );
}
