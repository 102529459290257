import React from 'react';
import { HowManyPixel } from './HowManyPixel';
import TopNotification from './TopNotification';
import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';
import SelectSite from './SelectSite';
import UserPreferences from './UserPreferences';
import DevPanel from './DevPanel';
import { DeviceTree, ISCRenameModal } from 'src/components/DeviceTree';
import { DeviceMenu } from 'src/components/DeviceMenu';
import { useTranslation } from 'react-i18next';

export default function MainContent() {
    const { t } = useTranslation();

    return (
        <>
            <Box>
                <TopNotification />
                <Box>
                    <Grid gridDefinition={[{ colspan: 1 }, { colspan: 2 }, { colspan: 8 }, { colspan: 1 }]}>
                        <div></div>
                        <Container header={<Header variant='h2'>{t('Actions')}</Header>}>
                            <DeviceMenu />
                        </Container>
                        <Container header={<Header variant='h2'>{t('Devices')}</Header>}>
                            <ISCRenameModal/>
                            <DeviceTree key={'tree'} />
                        </Container>
                        <div></div>
                    </Grid>
                </Box>
            </Box>
            <HowManyPixel />
            <SelectSite />
            <UserPreferences />
            <DevPanel />
            {/* <ResizeHandler /> */}
        </>
    );
}
