import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceFromDDV, TreeDevice } from 'src/types';
import { SelectProps } from '@amzn/awsui-components-react';
import { Site } from 'src/API';
import { log } from 'src/utils/helpers';

export interface DevicesSliceInterface {
    loadingDevices: boolean;
    parentDevices: TreeDevice[];
    childDevices: TreeDevice[];
    subchildDevices: TreeDevice[];
    allowedSites: string[];
}

export const initialDevicesState: DevicesSliceInterface = {
    loadingDevices: false,
    parentDevices: [],
    childDevices: [],
    subchildDevices: [],
    allowedSites: [],
};

export const devicesSlice = createSlice({
    name: 'devicesState',
    initialState: initialDevicesState,
    reducers: {
        setDevices: (state, action: PayloadAction<DeviceFromDDV[]>) => {
            const payload: DeviceFromDDV[] = action.payload;
            if (!payload) return;
            let parents: TreeDevice[] = [];
            let children: TreeDevice[] = [];
            let subchildren: TreeDevice[] = [];

            for (const device of payload) {
                if (device.child_device_id == 0 && device.subchild_device_id == 0) {
                    parents.push({
                        ...device,
                        deviceKey: `${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}`,
                        invalidName: false,
                        checked: false,
                        expanded: false,
                    });
                } else if (device.child_device_id != 0 && device.subchild_device_id == 0) {
                    children.push({
                        ...device,
                        deviceKey: `${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}`,
                        invalidName: false,
                        checked: false,
                        expanded: false,
                    });
                } else {
                    subchildren.push({
                        ...device,
                        deviceKey: `${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}`,
                        invalidName: false,
                        checked: false,
                        expanded: false,
                    });
                }
            }
            state.parentDevices = parents;
            state.childDevices = children;
            state.subchildDevices = subchildren;
        },
        setLoadingDevices: (state, action: { type: string; payload: boolean }) => {
            state.loadingDevices = action.payload;
        },
        setAllowedSites: (state, action: PayloadAction<Site[]>) => {
            if (action.payload != null) {
                state.allowedSites = action.payload.map((s) => {
                    return s.SiteCode!;
                });
            }
        },

        setCheckedParent: (state, action: PayloadAction<{ device: TreeDevice; checked: boolean }>) => {
            log(`Checking device ${action.payload.device.deviceKey} to ${action.payload.checked}`);
            const deviceIndex = state.parentDevices.findIndex((device) => {
                return device.deviceKey == action.payload.device.deviceKey;
            });
            if (deviceIndex == -1) {
                log('No device found');
                return;
            }
            state.parentDevices[deviceIndex] = {
                ...state.parentDevices[deviceIndex],
                checked: action.payload.checked,
            };
        },
        setCheckedChild: (state, action: PayloadAction<{ device: TreeDevice; checked: boolean }>) => {
            const deviceIndex = state.childDevices.findIndex((device) => {
                return device.deviceKey == action.payload.device.deviceKey;
            });
            if (deviceIndex == -1) {
                log('No device found');
                return;
            }
            state.childDevices[deviceIndex] = {
                ...state.childDevices[deviceIndex],
                checked: action.payload.checked,
            };
            
            parentChecker(action.payload.device, state)
        },
        setCheckedSubChild: (state, action: PayloadAction<{ device: TreeDevice; checked: boolean }>) => {
          const deviceIndex = state.subchildDevices.findIndex((device) => {
              return device.deviceKey == action.payload.device.deviceKey;
          });
          if (deviceIndex == -1) {
              log('No device found');
              return;
          }
          state.subchildDevices[deviceIndex] = {
              ...state.subchildDevices[deviceIndex],
              checked: action.payload.checked,
          };

          const child_id = action.payload.device.child_device_id;
          const parent_id = action.payload.device.parent_device_id;

          const allSubChildIsChecked = 
            state.subchildDevices.filter(device => 
              device.parent_device_id == parent_id 
              && device.child_device_id == child_id
            ).reduce((prev, cur) => {
              return prev && cur.checked;
            }, true);

          const childDeviceIndex = state.childDevices.findIndex((device) => {
            return device.child_device_id == child_id 
                  && device.parent_device_id == parent_id 
                  && device.subchild_device_id == 0;
          });

          if (childDeviceIndex == -1) {
            log('No device found');
            return;
          }
          state.childDevices[childDeviceIndex] = {
            ...state.childDevices[childDeviceIndex],
            checked: allSubChildIsChecked
          }

          if (allSubChildIsChecked) {
            parentChecker(action.payload.device, state);
          } else {
            const parentDeviceIndex = state.parentDevices.findIndex((device) => {
              return device.parent_device_id == parent_id && device.child_device_id === 0;
            });

            if (parentDeviceIndex == -1) {
              log('No device found');
              return;
            }
            state.parentDevices[parentDeviceIndex] = {
              ...state.parentDevices[parentDeviceIndex],
              checked: allSubChildIsChecked
            }
          }
        },
        setChildrenChecked: (state, action: PayloadAction<{ device: TreeDevice; checked: boolean }>) => {
            state.childDevices = state.childDevices.map((childDevice) => {
                if (childDevice.parent_device_id == action.payload.device.parent_device_id) {
                    if (action.payload.checked) {
                        childDevice.checked = true;
                        childDevice.expanded = true;
                    } else {
                        childDevice.checked = false;
                    }
                }
                return childDevice;
            });
        },
        setSubChildrenChecked: (state, action: PayloadAction<{ device: TreeDevice; checked: boolean }>) => {
            state.subchildDevices = state.subchildDevices.map((scDevice) => {
                if (
                    scDevice.parent_device_id == action.payload.device.parent_device_id &&
                    scDevice.child_device_id == action.payload.device.child_device_id &&
                    action.payload.device.subchild_device_id != 0
                ) {
                    if (action.payload.checked) {
                        scDevice.checked = true;
                        scDevice.expanded = true;
                    } else {
                        scDevice.checked = false;
                    }
                }
                return scDevice;
            });
        },
        setParentExpanded: (state, action: PayloadAction<{ device: TreeDevice; expanded: boolean }>) => {
            state.parentDevices = state.parentDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.expanded = action.payload.expanded;
                }
                return device;
            });
        },
        setChildExpanded: (state, action: PayloadAction<{ device: TreeDevice; expanded: boolean }>) => {
            state.childDevices = state.childDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.expanded = action.payload.expanded;
                }
                return device;
            });
        },
        setSubchildExpanded: (state, action: PayloadAction<{ device: TreeDevice; expanded: boolean }>) => {
            state.subchildDevices = state.subchildDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.expanded = action.payload.expanded;
                }
                return device;
            });
        },
        setParentDeviceName: (state, action: PayloadAction<{ device: TreeDevice; newName: string }>) => {
            state.parentDevices = state.parentDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.device_name = action.payload.newName;
                }
                return device;
            });
        },
        setChildDeviceName: (state, action: PayloadAction<{ device: TreeDevice; newName: string }>) => {
            state.childDevices = state.childDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.device_name = action.payload.newName;
                }
                return device;
            });
        },
        setSubChildDeviceName: (state, action: PayloadAction<{ device: TreeDevice; newName: string }>) => {
            state.subchildDevices = state.subchildDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.device_name = action.payload.newName;
                }
                return device;
            });
        },
        setParentErrorText: (state, action: PayloadAction<{ device: TreeDevice; errorText: string }>) => {
            state.parentDevices = state.parentDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.errorText = action.payload.errorText;
                }
                return device;
            });
        },
        setChildErrorText: (state, action: PayloadAction<{ device: TreeDevice; errorText: string }>) => {
            state.childDevices = state.childDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.errorText = action.payload.errorText;
                }
                return device;
            });
        },
        setSubChildErrorText: (state, action: PayloadAction<{ device: TreeDevice; errorText: string }>) => {
            state.subchildDevices = state.subchildDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.errorText = action.payload.errorText;
                }
                return device;
            });
        },
        setParentLoading: (state, action: PayloadAction<{ device: TreeDevice; loading: boolean }>) => {
            state.parentDevices = state.parentDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.loading = action.payload.loading;
                }
                return device;
            });
        },
        setChildLoading: (state, action: PayloadAction<{ device: TreeDevice; loading: boolean }>) => {
            state.childDevices = state.childDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.loading = action.payload.loading;
                }
                return device;
            });
        },
        setSubChildLoading: (state, action: PayloadAction<{ device: TreeDevice; loading: boolean }>) => {
            state.subchildDevices = state.subchildDevices.map((device) => {
                if (device.deviceKey == action.payload.device.deviceKey) {
                    device.loading = action.payload.loading;
                }
                return device;
            });
        },
    },
});

function parentChecker (device: TreeDevice, state: DevicesSliceInterface) {
  const parent_id = device.parent_device_id;
  const allIsChecked = 
    state.childDevices.filter(device => 
      device.parent_device_id === parent_id 
      && device.subchild_device_id === 0
    ).reduce((prev, cur) => {
      return prev && cur.checked;
    }, true);

  const parentDeviceIndex = state.parentDevices.findIndex((device) => {
    return device.parent_device_id == parent_id && device.child_device_id === 0;
  });

  if (parentDeviceIndex == -1) {
    log('No device found');
    return;
  }
  state.parentDevices[parentDeviceIndex] = {
    ...state.parentDevices[parentDeviceIndex],
    checked: allIsChecked
  }
}

export const {
    setDevices,
    setLoadingDevices,
    setAllowedSites,
    setCheckedParent,
    setCheckedSubChild,
    setCheckedChild,
    setChildrenChecked,
    setSubChildrenChecked,
    setParentExpanded,
    setChildExpanded,
    setSubchildExpanded,
    setChildDeviceName,
    setParentDeviceName,
    setSubChildDeviceName,
    setParentErrorText,
    setChildErrorText,
    setSubChildErrorText,
    setParentLoading,
    setChildLoading,
    setSubChildLoading,
} = devicesSlice.actions;
/*
export const selectLoadingDevices = (state: RootState) => state.deviceState.loadingDevices;
export const selectSelectedSites = (state: RootState) => state.deviceState.selectedSites;
export const selectAllowedSites = (state: RootState) => state.deviceState.allowedSites;
export const selectDevices = (state: RootState) => state.deviceState.devices;
*/
export default devicesSlice.reducer;
