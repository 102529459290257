import * as React from 'react';

interface IRawHTMLRendererProps {
    html: string;
}
export default class RawHTMLRenderer extends React.Component<IRawHTMLRendererProps, {}> {
    public container: HTMLElement | null = null;
    public shadowRoot: ShadowRoot | null = null;

    public componentDidMount() {
        this.updateContainerContent();
    }

    public componentDidUpdate() {
        this.updateContainerContent();
    }

    public render() {
        return (
            <div
                className='rawHtmlRenderer'
        ref={(node) => {
            if (node) this.container = node;
        }}
        />
    );
    }

    private updateContainerContent() {
        if (!this.container) {
            return;
        }

        if (!this.props.html) {
            return;
        }

        if (this.shadowRoot === null) {
            this.shadowRoot = this.container.attachShadow({ mode: 'open' });
        }

        this.shadowRoot.innerHTML = this.props.html;
    }
}
