import * as React from 'react';
import { useEffect } from 'react';
import { useAppSelector } from '../stores/slices/hooks';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../stores/slices/store';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Modal,
    SpaceBetween,
    Toggle,
} from '@amzn/awsui-components-react';
import { toggleUserPreferences } from '../stores/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { toggleDarkMode, toggleUiDensity, toggleVisualRefresh } from '../stores/slices/userPrefsSlice';

export default function UserPreferences() {
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const showUserPreferences = useAppSelector((state) => state.userState.showUserPreferences);
    const darkMode = useAppSelector((state) => state.userPrefsState.darkMode);
    const uiDensity = useAppSelector((state) => state.userPrefsState.uiDensity);
    const visualRefresh = useAppSelector((state) => state.userPrefsState.visualRefresh);

    function savePreferences() {
        // TODO: API connection
        // Preferences are automatically stored via the redux store (store.tsx)
        dispatch(toggleUserPreferences());
    }

    useEffect(() => {
        // Apply compact/comfortable density mode
        // if (uiDensity) {
        //     applyDensity(Density.Compact);
        // } else {
        //     applyDensity(Density.Comfortable);
        // }
        return () => {
            uiDensity;
        };
    }, [uiDensity]);

    if (showUserPreferences) {
        return (
            <Modal
                id={'userPreferencesModal'}
                visible={showUserPreferences}
                header={t('User Preferences')}
                onDismiss={() => dispatch(toggleUserPreferences())}
                closeAriaLabel={t('close user preferences')}
                size={'large'}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button id={'userprefs_closeButton'} variant='primary' onClick={savePreferences}>
                                Close
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <ColumnLayout columns={2}>
                    <Container>
                        <h2>UI Options</h2>
                        <Toggle
                            id={'userprefs_uiDensity'}
                            checked={uiDensity}
                            onChange={() => dispatch(toggleUiDensity())}
                            description={'Enable compact density mode (Default: True)'}
                        >
                            Enable Compact Density
                        </Toggle>
                        <Toggle
                            id={'userprefs_darkMode'}
                            checked={darkMode}
                            onChange={() => dispatch(toggleDarkMode())}
                            description={'Enable the dark mode theme (Default: False)'}
                        >
                            Enable Dark Mode
                        </Toggle>
                        <Toggle
                            id={'userprefs_visualRefresh'}
                            checked={visualRefresh}
                            onChange={() => dispatch(toggleVisualRefresh())}
                            description={'Enable the visual refresh look (Default: True)'}
                        >
                            Enable Visual Refresh
                        </Toggle>
                    </Container>
                </ColumnLayout>
            </Modal>
        );
    } else {
        return <></>;
    }
}
