import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { log } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from '../stores/slices/hooks';
import { setAuthToken, setUsername } from '../stores/slices/userSlice';
import { setDarkMode } from '../stores/slices/userPrefsSlice';
import { envVariables } from 'src/resources/envVariables';
import SIDEFARApp from 'src/components/SIDEFAR';
import Auth from '@aws-amplify/auth';

export default function App(props: any) {
    const stage = envVariables.stage;
    const darkMode = useAppSelector((state) => state.userPrefsState.darkMode);
    const visualRefresh = useAppSelector((state) => state.userPrefsState.visualRefresh);
    const username = useAppSelector((state) => state.userState.username);
    const dispatch = useAppDispatch();
    // window.addEventListener('resize', handleResize);

    useEffect(() => {
        switch (stage.toLowerCase()) {
            case 'prod':
                document.title = 'Guardian: FAR';
                break;
            default:
                document.title = `Guardian: FAR ${stage}`;
                break;
        }
    });

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('awsui-polaris-dark-mode');
        } else if (!darkMode) {
            document.body.classList.remove('awsui-polaris-dark-mode');
        }
        return () => {};
    }, [darkMode]);

    useEffect(() => {
        // Apply dark mode if browser in dark more, or if forced
        const matches = window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (matches) {
            dispatch(setDarkMode(true));
        }

        return () => {};
    }, [window.matchMedia('(prefers-color-scheme: dark)')]);

    useEffect(() => {
        if (visualRefresh) {
            document.body.classList.add('awsui-visual-refresh');
        } else {
            document.body.classList.remove('awsui-visual-refresh');
        }

        return () => {
            visualRefresh;
        };
    }, [visualRefresh]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((cognitoUserData) => {
                //setUserData(cognitoUserData);
                //setAuthLoaded(true);
                const username = cognitoUserData.username.split('_')[1];
                //Auth.currentAuthenticatedUser().then((e) => {
                dispatch(setAuthToken(cognitoUserData.getSignInUserSession().getIdToken().getJwtToken()));
                dispatch(setUsername(username));
                //});
            })
            .catch((err) => {
                log('No user signed in, performing federatedSignIn', false, {
                    error: err,
                });
                //setAuthLoaded(true);
                Auth.federatedSignIn({ customProvider: 'AmazonFederate' })
                    .then((e) => {
                        Auth.currentAuthenticatedUser()
                            .then((cognitoUserData) => {
                                // log(`new token: ${JSON.stringify(cognitoUserData)}`);
                                dispatch(
                                    setAuthToken(
                                        cognitoUserData.getSignInUserSession().getIdToken().getJwtToken(),
                                    ),
                                );
                                //log(`new token: ${JSON.stringify(token)}`);
                                dispatch(setUsername(username));
                            })
                            .catch((err) => {
                                log(`Federated sign in currentAuthenticatedUser failed with ${err}`, true);
                            });
                    })
                    .catch((err) => {
                        log('Federated sign in failed with ', true, err);
                    });
            });
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<SIDEFARApp {...props} />} {...props} />
            </Routes>
        </BrowserRouter>
    );
}
