import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlashbarMessage, TreeDevice } from 'src/types';
import { log } from 'src/utils/helpers';
import { SelectProps } from '@amzn/awsui-components-react';

export interface IUserSlice {
    userAuthenticated: boolean;
    username: string;
    authenticatedTimestamp: number;
    tzOffset: number;

    visualRefreshTheme: boolean;
    showUserPreferences: boolean;
    showSelectSite: boolean;
    showFileUpload: boolean;
    showISCRenameModal: boolean;
    ISCRenameData: {
        device: TreeDevice;
        newName: string;
    } | {};

    authToken: string;
    userNotifications: FlashbarMessage[];
}

export const initalUserState: IUserSlice = {
    userAuthenticated: false,
    username: '',
    authenticatedTimestamp: 0,
    tzOffset: new Date().getTimezoneOffset(),

    visualRefreshTheme: false,
    showUserPreferences: false,
    showSelectSite: true,
    showFileUpload: false,
    showISCRenameModal: false,
    ISCRenameData: {},

    authToken: '',
    userNotifications: [],
};

export const userSlice = createSlice({
    name: 'userState',
    initialState: initalUserState,
    reducers: {
        userDidAuthenticate: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.userAuthenticated = true;
        },
        setShowFileUpload: (state, action: PayloadAction<boolean>) => {
            state.showFileUpload = action.payload;
        },
        toggleShowFileUpload: (state) => {
            state.showFileUpload = !state.showFileUpload;
        },
        userDidLogoff: (state) => {
            state.userAuthenticated = false;
            state.username = '';
        },
        toggleUserPreferences: (state) => {
            state.showUserPreferences = !state.showUserPreferences;
        },
        toggleSelectSite: (state) => {
            state.showSelectSite = !state.showSelectSite;
        },
        toggleISCRenameModal: (state) => {
            state.showISCRenameModal = !state.showISCRenameModal;
        },
        setISCRenameData: (state, action) => {
            state.ISCRenameData = action.payload;
        },
        setUsername: (state, action) => {
            log(`setUsername: ${action.payload}`);
            state.username = action.payload.toString();
            state.userAuthenticated = true;
        },
        setAuthToken: (state, action) => {
            // log(`set federate token: `, false, action.payload);
            state.authToken = action.payload.toString();
        },
        addUserNotification: (state, action: PayloadAction<FlashbarMessage[]>) => {
            action.payload.forEach((message) => {
                state.userNotifications.push(message);
            });
        },
        setUserNotifications: (state, action: PayloadAction<FlashbarMessage[]>) => {
            log('Setting new notifications as ');
            log(action.payload);
            state.userNotifications = action.payload;
        },
        userNotificationCountdown: (state) => {
            if (state.showSelectSite || state.showUserPreferences) {
                return;
            } // Don't count down if there is a window open
            state.userNotifications = state.userNotifications
                .filter((n) => n.displayTimeout > 1)
                .map((n) => {
                    return {
                        displayTimeout: n.displayTimeout - 1,
                        type: n.type,
                        content: n.content,
                    };
                });
        },
        removeUserNotification: (state, action: PayloadAction<number>) => {
            //Payload is index to delete
            log(`Removing notification ${action.payload}`);
            state.userNotifications = state.userNotifications.splice(action.payload - 1, 1);
            let notifications: FlashbarMessage[] = [];

            state.userNotifications.forEach((n, index) => {
                if (index != action.payload) {
                    notifications.push(n);
                }
            });
            state.userNotifications = notifications;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    userDidAuthenticate,
    userDidLogoff,
    setUsername,
    toggleUserPreferences,
    toggleSelectSite,
    toggleISCRenameModal,
    setISCRenameData,
    setAuthToken,
    addUserNotification,
    removeUserNotification,
    setUserNotifications,
    userNotificationCountdown,
    toggleShowFileUpload,
    setShowFileUpload,
} = userSlice.actions;

// export const username = (state: RootState) => state.userState.username;

export const selectUsername = (state: any) => state.userState.username;

export default userSlice.reducer;
