import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from './store';
import { useEffect, useRef, useState } from 'react';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useComponentVisible(initialIsVisible: any) {
    const ref = useRef<HTMLDivElement>(null);
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    // log('setIsComponentVisible: ', false, setIsComponentVisible);
    const dispatch = useAppDispatch();

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}
