export interface FlashbarMessage {
    type: string;
    content: string;
    displayTimeout: number;
}

export interface DeviceFromDDV {
    FacMan_siteName: string;
    SiteCode: string;
    parent_device_name: string;
    segment_name: string;
    segment_id: number;
    parent_device_id: number;
    child_device_id: number;
    child_device_name: string;
    subchild_device_id: number;
    subchild_device_name: string;
    device_type_id: number;
    device_name: string;
    device_type_name: string;
    device_type_name_special: string;
    region_id: number;
    device_key: string;
    device_href: string;
    communication_address: number;
    communication_port: number;
    DeviceSource: 'lenel' | 'onguard' | 'keep';
}

export interface TreeDevice extends DeviceFromDDV {
    deviceKey: string;
    invalidName: boolean;
    checked: boolean;
    expanded: boolean;
    errorText?: string;
    loading?: boolean; // Indicates a server action is in progress
}

export const prefixes = ['uc_', 'dcmsn_', 'keep_'];