/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUserPrefs = /* GraphQL */ `query GetUserPrefs($id: ID!) {
  getUserPrefs(id: $id) {
    eventsTableLocation
    id
    lastConnected
    lastSites
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPrefsQueryVariables,
  APITypes.GetUserPrefsQuery
>;
export const getUserSites = /* GraphQL */ `query GetUserSites($userId: ID!) {
  getUserSites(userId: $userId) {
    SiteCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserSitesQueryVariables,
  APITypes.GetUserSitesQuery
>;
export const getUserInfo = /* GraphQL */ `query GetUserInfo($userId: ID!) {
  getUserInfo(userId: $userId) {
    userFullName
    userId
    userSid
    username
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInfoQueryVariables,
  APITypes.GetUserInfoQuery
>;
export const appSyncPing = /* GraphQL */ `query AppSyncPing {
  appSyncPing {
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AppSyncPingQueryVariables,
  APITypes.AppSyncPingQuery
>;
export const getUserNotifications = /* GraphQL */ `query GetUserNotifications($notificationType: String) {
  getUserNotifications(notificationType: $notificationType) {
    notificationType
    type
    content
    displayTimeout
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserNotificationsQueryVariables,
  APITypes.GetUserNotificationsQuery
>;
export const getDevicesForSites = /* GraphQL */ `query GetDevicesForSites($sites: String) {
  getDevicesForSites(sites: $sites) {
    DeviceSource
    SiteCode
    child_device_id
    child_device_name
    device_name
    device_type_id
    device_type_name
    device_type_name_special
    parent_device_id
    parent_device_name
    region_id
    subchild_device_id
    subchild_device_name
    device_key
    device_href
    communication_address
    communication_port
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDevicesForSitesQueryVariables,
  APITypes.GetDevicesForSitesQuery
>;
