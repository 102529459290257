import { TreeDevice } from 'src/types';
import { ThunkAction } from '@reduxjs/toolkit';
import {
    DevicesSliceInterface,
    setCheckedChild,
    setCheckedParent,
    setCheckedSubChild,
    setChildDeviceName,
    setChildErrorText,
    setChildExpanded,
    setChildLoading,
    setParentDeviceName,
    setParentErrorText,
    setParentExpanded,
    setParentLoading,
    setSubChildDeviceName,
    setSubChildErrorText,
    setSubChildLoading,
} from 'src/stores/slices/devicesSlice';
import { TAppState, TDispatch, TGetState } from 'src/stores/slices/store';
import {
    isDeviceNameValid,
    checkDeviceSiteCodeMatchesParent,
    getNameWithoutPrefix,
    getParentSiteCode,
    isDeviceChild,
    isDeviceParent,
    isDeviceSubChild,
    log,
    getPrefixFromName,
    prefixWasAdded,
    prefixWasRemoved,
    INVALID_NAME_SUBMITTED_METRIC_NAME,
    putMetricSilent
} from 'src/utils/helpers';
import { API, graphqlOperation } from '@aws-amplify/api';
import { renameDevices } from 'src/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { DeviceInput, RenameDevicesMutation } from 'src/API';

interface DispatchProps {
    login: (username: string, password: string) => void;
}

interface IState {
    deviceState: DevicesSliceInterface;
}

// type TPromise = Promise<{ error: string } | Error>; // Return type instead of void
export const setChecked =
    (props: {
        device: TreeDevice;
        checked: boolean;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'SET_CHECKED'; payload: { device: TreeDevice; checked: boolean } } // action type
    > =>
        (dispatch: TDispatch, getState: TGetState): void => {
            // use getState
            // dispatch start saveEntry action
            // make async call
            let toCheckExpand: TreeDevice[] = [];
            if (props.device.child_device_id == 0 && props.device.subchild_device_id == 0) {
                dispatch(setCheckedParent({ device: props.device, checked: props.checked }));
                if (props.checked) {
                    dispatch(setParentExpanded({ device: props.device, expanded: true }));
                }
                for (const cDevice of getState().deviceState.childDevices) {
                    if (cDevice.parent_device_id == props.device.parent_device_id) {
                        dispatch(setCheckedChild({ device: cDevice, checked: props.checked }));
                        // Expand children if parent was selected
                        if (props.checked) {
                            dispatch(setChildExpanded({ device: cDevice, expanded: true }));
                        }
                    }
                }
                for (const scDevice of getState().deviceState.subchildDevices) {
                    if (props.device.parent_device_id == scDevice.parent_device_id) {
                        dispatch(setCheckedSubChild({ device: scDevice, checked: props.checked }));
                    }
                }
                // dispatch(setSubChildrenChecked({ device: props.device, checked: props.checked }));
            } else if (props.device.child_device_id != 0 && props.device.subchild_device_id == 0) {
                dispatch(setCheckedChild({ device: props.device, checked: props.checked }));
                if (props.checked) {
                    dispatch(setChildExpanded({ device: props.device, expanded: true }));
                }
                // dispatch(setSubChildrenChecked({ device: props.device, checked: props.checked }));
                for (const scDevice of getState().deviceState.subchildDevices) {
                    if (
                        props.device.parent_device_id == scDevice.parent_device_id &&
                        props.device.child_device_id == scDevice.child_device_id
                    ) {
                        dispatch(setCheckedSubChild({ device: scDevice, checked: props.checked }));
                    }
                }
            } else {
                dispatch(setCheckedSubChild({ device: props.device, checked: props.checked }));
            }
            return;
        };

function isChecked(device: TreeDevice) {
    if (device.checked) {
        return true;
    }
}

export const addTextToSelected =
    (props: {
        toAdd: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'ADD_TEXT_TO_SELECTED'; payload: { toAdd: string } } // action type
    > =>
        (dispatch: TDispatch, getState: TGetState): void => {
            const parentDevices = getState().deviceState.parentDevices.filter(isChecked);
            const childDevices = getState().deviceState.childDevices.filter(isChecked);
            const subchildDevices = getState().deviceState.subchildDevices.filter(isChecked);

            for (const device of parentDevices.concat(childDevices).concat(subchildDevices)) {
                if (!device.device_name.includes(`${props.toAdd}`)
                    && !(device.DeviceSource === 'keep' && props.toAdd === 'keep_')) {
                    dispatch(deviceNameChanged({
                        device,
                        newName: `${props.toAdd}${getNameWithoutPrefix(device.device_name)}`,
                        isBulkRename: true
                    }));
                }
            }
        };

export const removeTextFromSelected =
    (props: {
        toRemove: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'REMOVE_TEXT_FROM_SELECTED'; payload: { toRemove: string } } // action type
    > =>
        (dispatch: TDispatch, getState: TGetState): void => {
            const parentDevices = getState().deviceState.parentDevices.filter(isChecked);
            const childDevices = getState().deviceState.childDevices.filter(isChecked);
            const subchildDevices = getState().deviceState.subchildDevices.filter(isChecked);

            for (const device of parentDevices.concat(childDevices).concat(subchildDevices)) {
                if (device.device_name.includes(`${props.toRemove}`)) {
                    const newName = device.device_name.replace(`${props.toRemove}`, '');
                    dispatch(deviceNameChanged({ device, newName, isBulkRename: true }));
                }
            }
        };

export const setDeviceLoading =
    (props: {
        device: TreeDevice;
        loading: boolean;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'CHANGE_DEVICE_NAME'; payload: { device: TreeDevice; loading: boolean } } // action type
    > =>
        async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
            if (props.device.child_device_id == 0 && props.device.subchild_device_id == 0) {
                dispatch(setParentLoading({ device: props.device, loading: props.loading }));
            } else if (props.device.child_device_id != 0 && props.device.subchild_device_id == 0) {
                dispatch(setChildLoading({ device: props.device, loading: props.loading }));
            } else {
                dispatch(setSubChildLoading({ device: props.device, loading: props.loading }));
            }
        };

export const changeNameById =
    (props: {
        parentId: number;
        childId: number;
        subchildId: number;
        newName: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'CHANGE_DEVICE_NAME'; payload: { device: TreeDevice; newName: boolean } } // action type
    > =>
        async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
            log(`Device by id ${props.newName}`);
            if (props.childId == 0 && props.subchildId == 0) {
                // panel
                getState().deviceState.parentDevices.forEach((device) => {
                    if (device.parent_device_id == props.parentId) {
                        dispatch(deviceNameChanged({ device: device, newName: props.newName }));
                        log(
                            `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`,
                        );
                    }
                });
            } else if (props.subchildId == 0) {
                // child
                getState().deviceState.childDevices.forEach((device) => {
                    if (device.parent_device_id == props.parentId && device.child_device_id == props.childId) {
                        dispatch(deviceNameChanged({ device: device, newName: props.newName }));

                        log(
                            `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`,
                        );
                    }
                });
            } else {
                // subchild
                getState().deviceState.subchildDevices.forEach((device) => {
                    if (
                        device.parent_device_id == props.parentId &&
                        device.child_device_id == props.childId &&
                        device.subchild_device_id == props.subchildId
                    ) {
                        dispatch(deviceNameChanged({ device: device, newName: props.newName }));

                        log(
                            `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`,
                        );
                    }
                });
            }
        };

export const deviceNameChanged =
    (props: {
        device: TreeDevice;
        newName: string;
        isParentRename?: boolean;
        isBulkRename?: boolean;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'CHANGE_DEVICE_NAME'; payload: { device: TreeDevice; newName: string } } // action type
    > =>
        async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
            log(`Device name to appsync ${props.newName}`);
            let errorText = '';
            dispatch(setDeviceLoading({ device: props.device, loading: true }));

            const parentDeviceName = getState().deviceState.parentDevices.find(device =>
                props.device.parent_device_id === device.parent_device_id)?.device_name!;

            if (props.device.device_name !== props.newName) {

                if (props.device.device_type_name_special === 'lidar' &&
                    getNameWithoutPrefix(props.device.device_name) !== getNameWithoutPrefix(props.newName)) {
                    dispatch(setDeviceLoading({ device: props.device, loading: false }));
                    dispatch(getErrorMethod(props.device, 'Only changing prefix is allowed for LIDAR devices.'));
                    return;
                }

                if (checkDupes(props.device, props.newName, getState())) {
                    dispatch(setDeviceLoading({ device: props.device, loading: false }));
                    dispatch(getErrorMethod(props.device, 'Duplicate name found'));
                    await putMetricSilent(INVALID_NAME_SUBMITTED_METRIC_NAME, 1);
                    return;
                }

                log('No duplicates found');

                const newDevice: DeviceInput = {
                    DeviceSource: props.device.DeviceSource,
                    SiteCode: props.device.SiteCode,
                    child_device_id: props.device.child_device_id,
                    child_device_name: isDeviceChild(props.device) ? props.newName : props.device.child_device_name,
                    device_name: props.newName,
                    device_type_id: props.device.device_type_id,
                    device_type_name: props.device.device_type_name,
                    device_type_name_special: props.device.device_type_name_special,
                    parent_device_id: props.device.parent_device_id,
                    parent_device_name: isDeviceParent(props.device) ? props.newName : props.device.parent_device_name,
                    region_id: props.device.region_id,
                    subchild_device_id: props.device.subchild_device_id,
                    subchild_device_name: isDeviceSubChild(props.device) ? props.newName : props.device.subchild_device_name,
                    device_key: props.device.device_key as string,
                    device_href: props.device.device_href,
                    communication_address: props.device.communication_address,
                    communication_port: props.device.communication_port
                };

                const deviceForChecks = newDevice as unknown as TreeDevice;
                if (!isDeviceNameValid(deviceForChecks, parentDeviceName)) {
                    errorText = 'Device name is invalid';
                } else if (!props.isParentRename && !isDeviceParent(deviceForChecks) &&
                    !checkDeviceSiteCodeMatchesParent(deviceForChecks.device_name, parentDeviceName)) {
                    errorText = 'Site code does not match parent site code.';
                } else if (!isDeviceSubChild(props.device) && prefixWasAdded(props.device.device_name, props.newName)) {
                    errorText = checkAncestorPrefixAdd(props.device, props.newName, props.isBulkRename ?? false, getState().deviceState);
                } else if (!isDeviceParent(props.device) && prefixWasRemoved(props.device.device_name, props.newName)) {
                    errorText = checkAncestorPrefixRemove(props.device, getState().deviceState);
                }

                // if child or subchild prefix is removed, remove ancestor prefix as well.
                // starting this before actual request for the child will make the request faster and more noticeable.
                if (
                    !errorText &&
                    !isDeviceParent(props.device) &&
                    prefixWasRemoved(props.device.device_name, props.newName)
                ) {
                    removeAncestorPrefixes(props.device, getState().deviceState, dispatch);
                }

                if (!errorText) {
                    let appsyncResponse: GraphQLResult<RenameDevicesMutation> = {};
                    try {
                        appsyncResponse = await (API.graphql(
                            graphqlOperation(renameDevices, { devices: [newDevice] }),
                        ) as Promise<GraphQLResult<RenameDevicesMutation>>);
                    } catch (e) {
                        log('Error with renaming device', false, { error: e });
                    }

                    if (!appsyncResponse || !appsyncResponse.data?.renameDevices) {
                        // Set error text if response is not true
                        errorText = 'Server error, please try again later';
                        log(`Appsync server error ${props.device.deviceKey}`, true);
                    } else {
                        if (isDeviceParent(props.device)) {
                            dispatch(setParentDeviceName({ device: props.device, newName: props.newName }));
                        } else if (isDeviceChild(props.device)) {
                            dispatch(setChildDeviceName({ device: props.device, newName: props.newName }));
                        } else {
                            dispatch(setSubChildDeviceName({ device: props.device, newName: props.newName }));
                        }
                    }
                }
            } else {
                log('No change needed');
                errorText = '';
                if (!isDeviceNameValid(props.device, parentDeviceName)) {
                    await putMetricSilent(INVALID_NAME_SUBMITTED_METRIC_NAME, 1);
                }
            }
            // Update error text field or remove it if empty
            dispatch(getErrorMethod(props.device, errorText));

            dispatch(setDeviceLoading({ device: props.device, loading: false }));
            if (errorText) {
                await putMetricSilent(INVALID_NAME_SUBMITTED_METRIC_NAME, 1);
            }
        };

const getErrorMethod = (device: TreeDevice, errorText: string) => {
    const payload = { device, errorText };
    if (isDeviceParent(device)) {
        return setParentErrorText(payload);
    } else if (isDeviceChild(device)) {
        return setChildErrorText(payload);
    } else {
        return setSubChildErrorText(payload);
    }
}

const checkDupes = (device: TreeDevice, newName: string, state: TAppState): boolean => {
    const devices = getDeviceHierarchyLevel(device, state);
    const duplicate = devices.find(curDevice =>
        curDevice.DeviceSource === device.DeviceSource &&
        curDevice.deviceKey !== device.deviceKey &&
        curDevice.device_type_name_special === device.device_type_name_special &&
        curDevice.device_name === newName
    );
    return duplicate !== undefined;
}

const getDeviceHierarchyLevel = (device: TreeDevice, state: TAppState) => {
    if (isDeviceParent(device)) {
        return state.deviceState.parentDevices;
    } else if (isDeviceChild(device)) {
        return state.deviceState.childDevices;
    } else {
        return state.deviceState.subchildDevices;
    }
}

export const renameISC =
    (props: {
        device: TreeDevice;
        newName: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'RENAME_ISC_DEVICE_NAME'; payload: { device: TreeDevice; newName: string } } // action type
    > =>
        (dispatch: TDispatch, getState: TGetState): void => {

            dispatch(deviceNameChanged({ device: props.device, newName: props.newName }));
            const newSiteCode = getParentSiteCode(props.newName);

            if (!isDeviceNameValid({ ...props.device, device_name: props.newName }) ||
                checkDupes(props.device, props.newName, getState())) {

                log('Site code is invalid, will not proceed with renaming descendants.')
                return;
            }
            log('Updating descentant device site codes.');

            function replaceSiteCode(oldName: string, newSiteCode?: string) {
                return newSiteCode ? oldName.replace(/([A-Za-z0-9]{4,6})\-/, newSiteCode + '-') : oldName;
            }

            getState().deviceState.childDevices
                .filter(childDevice => childDevice.parent_device_id === props.device.parent_device_id)
                .forEach(childDevice => {
                    const newDeviceName = replaceSiteCode(childDevice.device_name, newSiteCode);
                    if (newDeviceName !== childDevice.device_name) {
                        console.log(`${childDevice.device_name} => ${newDeviceName}`);
                        dispatch(deviceNameChanged({ device: childDevice, newName: newDeviceName, isParentRename: true }));
                    }
                });

            getState().deviceState.subchildDevices
                .filter(subchildDevice => subchildDevice.parent_device_id === props.device.parent_device_id)
                .forEach(subchildDevice => {
                    const newDeviceName = replaceSiteCode(subchildDevice.device_name, newSiteCode);
                    if (newDeviceName !== subchildDevice.device_name) {
                        console.log(`${subchildDevice.device_name} => ${newDeviceName}`);
                        dispatch(deviceNameChanged({ device: subchildDevice, newName: newDeviceName, isParentRename: true }));
                    }
                });
        }

function checkAncestorPrefixAdd(device: TreeDevice, newName: string, isBulkRename: boolean, devices: DevicesSliceInterface): string {
    const prefixAdded = getPrefixFromName(newName)!;
    // if ancestor is selected, then descendants are also selected and about to be renamed
    // we need to look at if the descendants can be renamed, if not, prevent current device rename
    if (isBulkRename) {
        if (isDeviceParent(device)) {
            if (devices
                .childDevices
                .find(child =>
                    child.parent_device_id === device.parent_device_id &&
                    !child.device_name.includes(prefixAdded) &&
                    !isDeviceNameValid(child))) {
                return 'Cannot add prefix, child device cannot be renamed.';
            }
            if (devices
                .subchildDevices
                .find(subchild =>
                    subchild.parent_device_id === device.parent_device_id &&
                    !subchild.device_name.includes(prefixAdded) &&
                    !isDeviceNameValid(subchild))) {
                return 'Cannot add prefix, subchild device cannot be renamed.'
            }
        }
        if (devices
            .subchildDevices
            .find(subchild =>
                subchild.parent_device_id === device.parent_device_id &&
                subchild.child_device_id === device.child_device_id &&
                !subchild.device_name.includes(prefixAdded) &&
                !isDeviceNameValid(subchild))) {
            return 'Cannot add prefix, subchild device cannot be renamed.'
        }
    } else { // if it's not selected, all descendants hould have the prefix already
        if (isDeviceParent(device)) {
            if (devices
                .childDevices
                .find(child =>
                    child.parent_device_id === device.parent_device_id &&
                    !child.device_name.includes(prefixAdded))) {
                return `Cannot add prefix, child device does not have ${prefixAdded} prefix.`
            }
            if (devices
                .subchildDevices
                .find(
                    subchild =>
                        subchild.parent_device_id === device.parent_device_id &&
                        !subchild.device_name.includes(prefixAdded))) {
                return `Cannot add prefix, subchild device does not have ${prefixAdded} prefix.`
            }
        }

        if (devices
            .subchildDevices
            .find(
                subchild =>
                    subchild.parent_device_id === device.parent_device_id &&
                    subchild.child_device_id === device.child_device_id &&
                    !subchild.device_name.includes(prefixAdded))) {
            return `Cannot add prefix, subchild device does not have ${prefixAdded} prefix.`
        };
    }
    return '';
}

function checkAncestorPrefixRemove(device: TreeDevice, devices: DevicesSliceInterface): string {
    const prefixRemoved = getPrefixFromName(device.device_name)!;
    if (isDeviceSubChild(device)
        && devices.childDevices.find(child =>
            child.parent_device_id === device.parent_device_id &&
            child.child_device_id === device.child_device_id &&
            child.device_name.includes(prefixRemoved) &&
            !isDeviceNameValid(child)
        )
    ) {
        return `Cannot remove prefix, child device has an invalid name.`;
    }

    if (devices.parentDevices.find(parent =>
        parent.parent_device_id === device.parent_device_id &&
        parent.device_name.includes(prefixRemoved) &&
        !isDeviceNameValid(parent)
    )) {
        return `Cannot remove prefix, parent device has an invalid name.`;
    }
    return '';
}

function removeAncestorPrefixes(device: TreeDevice, devices: DevicesSliceInterface, dispatch: TDispatch) {
    const ancestorDevice =
        isDeviceSubChild(device) ?
            devices.childDevices.find(
                childDevice => childDevice.parent_device_id === device.parent_device_id && childDevice.child_device_id === device.child_device_id
            ) :
            devices.parentDevices.find(
                parentDevice => isDeviceParent(parentDevice) && parentDevice.parent_device_id === device.parent_device_id
            );
    if (ancestorDevice) {
        dispatch(deviceNameChanged({ device: ancestorDevice, newName: getNameWithoutPrefix(ancestorDevice.device_name) }));
    }
}

