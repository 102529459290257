import React, { useState } from 'react';
import {
    Alert,
    Badge,
    Box,
    Button,
    Container,
    FormField,
    Header,
    Modal,
    SpaceBetween,
    StatusIndicator,
    Table,
} from '@amzn/awsui-components-react';
import { getDeviceById, log } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { GREENLIGHT_CSV_HEADERS } from 'src/resources/constants';
import { TreeDevice } from 'src/types';
import { changeNameById } from 'src/components/actions/thunks';
import { setShowFileUpload } from 'src/stores/slices/userSlice';
import { useTranslation } from 'react-i18next';

export interface CsvDevice {
    parentId: number;
    childId: number;
    subchildId: number;
    deviceName: string;
    currentName?: string;
    newName?: string;
    status?: boolean;
    errorText?: string;
    loading?: boolean;
}

function DeviceList(props: { devices: CsvDevice[] }) {
    const { t } = useTranslation();
    const parentDevices = useAppSelector((state) => state.deviceState.parentDevices);
    const childDevices = useAppSelector((state) => state.deviceState.childDevices);
    const subchildDevices = useAppSelector((state) => state.deviceState.subchildDevices);

    if (!props.devices.length) {
        log('No CSV devices');

        return <></>;
    }

    const deviceList: CsvDevice[] = props.devices.map((device) => {
        let siteDevice: TreeDevice | undefined;
        if (device.childId == 0 && device.subchildId == 0) {
            siteDevice = getDeviceById(parentDevices, device.parentId, device.childId, device.subchildId);
        } else if (device.subchildId == 0) {
            siteDevice = getDeviceById(childDevices, device.parentId, device.childId, device.subchildId);
        } else {
            siteDevice = getDeviceById(subchildDevices, device.parentId, device.childId, device.subchildId);
        }

        if (siteDevice) {
            return {
                ...device,
                currentName: siteDevice.device_name,
                loading: siteDevice.loading,
                errorText: siteDevice.errorText,
            };
        } else {
            return {
                ...device,
                currentName: device.deviceName,
                loading: device.loading,
                errorText: 'Device not found',
            };
        }
    });

    if (deviceList.length > 0) {
        return (
            <>
                <Table
                    columnDefinitions={[
                        {
                            id: 'oldName',
                            header: t('Current Name'),
                            cell: (item) => item.currentName || '-',
                            sortingField: 'oldName',
                        },
                        {
                            id: 'newName',
                            header: t('New Name'),
                            cell: (item) => item.newName || '-',
                            sortingField: 'newName',
                        },
                        {
                            id: 'status',
                            header: t('Status'),
                            cell: (item) => {
                                if (item.errorText) {
                                    return <Badge color={'red'}>{t(item.errorText)}</Badge>;
                                }
                                if (item.currentName === item.newName) {
                                    return <StatusIndicator type={'success'}>{t('Success')}</StatusIndicator>;
                                }
                                if (item.loading) {
                                    return <StatusIndicator type={'loading'}>{t('Working')}</StatusIndicator>;
                                }
                                return <StatusIndicator type={'stopped'}></StatusIndicator>;
                            },
                            sortingField: 'status',
                        },
                    ]}
                    visibleColumns={['oldName', 'newName', 'status']}
                    items={deviceList}
                    sortingDisabled
                    sortingColumn={{ sortingField: 'status' }}
                    empty={<></>}
                    header={<Header> Names to be changed </Header>}
                />
            </>
        );
    } else {
        return <></>;
    }
}

function readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
            if (fr.result) resolve(fr.result?.toString());
            else reject([]);
        };
        fr.onerror = reject;
        fr.readAsText(file);
    });
}

async function loadCsv(file: File): Promise<CsvDevice[]> {
    const outDevices: CsvDevice[] = [];
    const fileText = await readFile(file);
    const csvToArray = fileText
        .split('\n') // split string to lines
        .map((e) => e.trim()) // remove white spaces for each line
        .map((e) => e.split(',').map((e) => e.trim())); // split each line to array
    const headers = csvToArray.shift();
    if (!headers || headers.length < 4) {
        throw new Error("Invalid file headers length");
    }
    for (const header of headers) {
        if (!GREENLIGHT_CSV_HEADERS.includes(header)) {
            throw new Error("Invalid file header detected");
        }
    }
    log('Csv to array', false, { csv: csvToArray });
    csvToArray?.forEach((line) => {
        if (line.length < 4) {
            log('Bad line, skipping', false, { line });
            return;
        }
        outDevices.push({
            parentId: parseInt(line[0]),
            childId: parseInt(line[1]),
            subchildId: parseInt(line[2]),
            deviceName: line[3],
            newName: line[3] ? line[3].replace('uc_', '').replace('dcmsn_', '') : null,
        } as CsvDevice);
    });
    log('Devices: ', false, { devices: outDevices });
    return outDevices;
}

export default function FileUpload() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [csvDevices, setCsvDevices] = useState<CsvDevice[]>([]);
    const showFileUpload = useAppSelector((state) => state.userState.showFileUpload);
    const hiddenFileInput = React.createRef<HTMLInputElement>();
    const [importError, setImportError] = useState<string>('');

    function closeFileUpload() {
        setImportError('');
        setCsvDevices([]);
        dispatch(setShowFileUpload(false));
    }

    async function fileChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        setImportError('');
        if (event.target.files && event.target.files[0]) {
            loadCsv(event.target.files[0])
                .then((devices) => {
                    log('Devices from loadCsv', false, { devices: devices });
                    if (devices.length) {
                        log('Devices loaded', false, { devices: devices });
                        setCsvDevices(devices);
                    } else {
                        log('No devices loaded');
                        setImportError('No Devices Found, please check the format of the file and try again');
                    }
                })
                .catch((e) => {
                    log('Error importing CSV', true, { error: e });
                    setImportError('Upload CSV Failed, please check the format of the file and try again');
                });
        }
        if (hiddenFileInput.current?.value) hiddenFileInput.current.value = '';
    }

    function acceptHandler() {
        // Push to appsync, devices will be updated async
        const newDevices = csvDevices;
        for (const device of newDevices) {
            if (device.newName) {
                device.loading = true;
                dispatch(
                    changeNameById({
                        newName: device.newName,
                        parentId: device.parentId,
                        childId: device.childId,
                        subchildId: device.subchildId,
                    })
                );
            }
        }
        setCsvDevices(newDevices);
    }

    return (
        <Modal
            visible={showFileUpload}
            onDismiss={closeFileUpload}
            closeAriaLabel={'close csv file upload window'}
            header={'Select file to upload'}
            size={'large'}
            footer={
                <>
                    <Box float={'right'}>
                        <SpaceBetween size={'m'} direction={'horizontal'}>
                            <Button
                                data-testid='upload-accept-button'
                                onClick={acceptHandler}
                                disabled={csvDevices.length <= 0}
                            >
                                Accept/Continue
                            </Button>
                            <Button data-testid='upload-cancel-button' onClick={closeFileUpload}>Cancel</Button>
                        </SpaceBetween>
                    </Box>
                </>
            }
        >
            {importError !== '' &&
                <Alert
                  data-testid='import-error-alert'
                  statusIconAriaLabel='Error'
                  type='error'
                >
                    {importError}
                </Alert>
            }
            <Container>
                <p>{t('Choose a file to upload. The file must be an export from Greenlight')}</p>
                <FormField
                    id='csvUploadForm'
                    // label={t('CSV Upload')}
                >
                    <input
                        data-testid='file-input'
                        ref={hiddenFileInput}
                        id='chooseCsvInput'
                        type='file'
                        hidden
                        multiple={false}
                        accept='text/csv'
                        onChange={fileChangeHandler}
                    />
                    <Button
                        iconName={'upload'}
                        formAction='none'
                        onClick={(e) => {
                            hiddenFileInput.current?.click();
                        }}
                    >
                        Choose CSV File
                    </Button>
                </FormField>
            </Container>
            <DeviceList devices={csvDevices} />
        </Modal>
    );
}
